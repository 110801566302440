import React, { useEffect, useState } from "react";
import Nav from "./Nav.js";
import Footer from "./Footer.js";
import SEO from "./SEO.js";
import "normalize.css";
import GlobalStyles from "../styles/GlobalStyles.js";
import SignUp from "./SignUp";
import Icons from "./Icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Layout({ children, data, location, url }) {
    const [isActive, setActive] = useState(true);
    const [isRedirect, setRedirect] = useState(false);
    const {settings} = data;
    const themes = data.themes.nodes;
    const regions = data.regions.nodes;
    const SEOOverrides = {};
    let pb;
    SEOOverrides.robots = "all";
    
    let app = (process.env.GATSBY_APP_ENV) ;

    // get / set initial entered page in session storage for use in utm params
    if (typeof window !== "undefined") {
      !window.sessionStorage.getItem("landingUrl") ? window.sessionStorage.setItem("landingUrl", "/" + url) : null;
    }

    if (typeof window !== "undefined") {
      if (window.location.href.includes('/redirect') && !isRedirect) {
        setRedirect(true);
      }
    }

    // SEO overrides
    switch(true) {
        case data.region !== undefined:
            data.region.titleSEO ? SEOOverrides['title'] = data.region.titleSEO : null;
            data.region.descriptionSEO ? SEOOverrides['description'] = data.region.descriptionSEO : null;
            data.region.keywordsSEO ? data.region.keywordsSEO.length > 0 ? SEOOverrides['keywords'] = data.region.keywordsSEO : null : null;
            pb = data.region.pageBuilder || [];
          break;
        case data.ppc !== undefined:
            data.ppc.titleSEO ? SEOOverrides['title'] = data.ppc.titleSEO : null;
            data.ppc.descriptionSEO ? SEOOverrides['description'] = data.ppc.descriptionSEO : null;
            data.ppc.keywordsSEO ? data.ppc.keywordsSEO.length > 0 ? SEOOverrides['keywords'] = data.ppc.keywordsSEO : null : null;
            SEOOverrides['robots'] = "noindex";
            pb = data.ppc.pageBuilder || [];
          break;
        case data.aboutUs:
            data.aboutUs.titleSEO ? SEOOverrides['title'] = data.aboutUs.titleSEO : null;
            data.aboutUs.descriptionSEO ? SEOOverrides['description'] = data.aboutUs.descriptionSEO : null;
            data.aboutUs.keywordsSEO ? data.aboutUs.keywordsSEO.length > 0 ? SEOOverrides['keywords'] = data.aboutUs.keywordsSEO : null : null;
          break;
        case data.terms !== undefined:
            data.terms.titleSEO ? SEOOverrides['title'] = data.terms.titleSEO : null;
            data.terms.descriptionSEO ? SEOOverrides['description'] = data.terms.descriptionSEO : null;
            data.terms.keywordsSEO ? data.terms.keywordsSEO.length > 0 ? SEOOverrides['keywords'] = data.terms.keywordsSEO : null : null;
          break;
        case data.theme !== undefined:
            data.theme.titleSEO ? SEOOverrides['title'] = data.theme.titleSEO : null;
            data.theme.descriptionSEO ? SEOOverrides['description'] = data.theme.descriptionSEO : null;
            data.theme.keywordsSEO ? data.theme.keywordsSEO.length > 0 ? SEOOverrides['keywords'] = data.theme.keywordsSEO : null : null;
            pb = data.theme.pageBuilder || [];
          break;
        case data.themedRegion !== undefined:
            data.themedRegion.titleSEO ? SEOOverrides['title'] = data.themedRegion.titleSEO : null;
            data.themedRegion.descriptionSEO ? SEOOverrides['description'] = data.themedRegion.descriptionSEO : null;
            data.themedRegion.keywordsSEO ? data.themedRegion.keywordsSEO.length > 0 ? SEOOverrides['keywords'] = data.themedRegion.keywordsSEO : null : null;
            pb = data.themedRegion.pageBuilder || [];
          break;
        case data.blog !== undefined:
            data.blog.titleSEO ? SEOOverrides['title'] = data.blog.titleSEO : null;
            data.blog.descriptionSEO ? SEOOverrides['description'] = data.blog.descriptionSEO : null;
            data.blog.keywordsSEO ? data.blog.keywordsSEO.length > 0 ? SEOOverrides['keywords'] = data.blog.keywordsSEO : null : null;
          break;
        // this is the /blog inspiration page
        case data.blogs !== undefined:
            SEOOverrides['title'] = "Inspiration - " + data.settings.title
          break;
        case data.privacyPolicy !== undefined:
            data.privacyPolicy.titleSEO ? SEOOverrides['title'] = data.privacyPolicy.titleSEO : null;
            data.privacyPolicy.descriptionSEO ? SEOOverrides['description'] = data.privacyPolicy.descriptionSEO : null;
            data.privacyPolicy.keywordsSEO ? data.privacyPolicy.keywordsSEO.length > 0 ? SEOOverrides['keywords'] = data.privacyPolicy.keywordsSEO : null : null;
          break;
        case data.home !== undefined:
            pb = data.home.pageBuilder || [];
          break;
        case isRedirect:
          SEOOverrides['title'] = `You’re are being transferred from ${data.settings.title}`;
          break;
        default:
          SEOOverrides['title'] = "Contact us - " + data.settings.title;
          break;
      }

    // this switch is checking if the pagebuilder contains the trustpiolt component
    // and if so it will set SEOOverrides['trustPilot'] to true
    // which then appends a tp script in the head in the SEO component
    (pb || []).map((c, i) => {
        switch (c._type) {
          case "trustPilot":
            SEOOverrides['trustPilot'] = true
          break;
        }
      });

    let themeArray= [];

    // transform themes to array
    for (const theme in themes) {
        themeArray.push( {"name":themes[theme].name, "slug":themes[theme].slug, "showInNav":themes[theme].showInNav});
    }

    useEffect(() => {
        if (typeof window !== "undefined"){
            if (localStorage.getItem("popUpSeen") === "true") {
                setActive(false);
            }
        }
    }, []);

    const hidePopup = () => {
        setActive(false);
        localStorage.setItem("popUpSeen", "true");
    };

    return (
        <>
            <Icons />
            <SEO {...settings} {...SEOOverrides} path={url}/>
            <GlobalStyles {...settings} app={app}/>
            <Nav regions={regions} settings={settings} themeArray={themeArray} location={location}/>
            {isActive && (
                <SignUp hidePopup={hidePopup} settings={settings}/>
            )}
            {children}
            <Footer {...settings}/>
        </>
    );
}