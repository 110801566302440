import React from "react";
import styled from "styled-components";
import Snaptrip from "../assets/icons/snaptrip.svg";

const Background = styled.div`
  background: var(--mdGrey);
  width: 100vw;
  height: 2.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 2.75rem;
  padding: 0 1.25rem;

  span {
    display: inline-block;
    font-size: 0.8rem;
    color: var(--white);
  }

  a {
    display: flex;

    img {
      height: 2.75rem;
      width: 2.5rem;
      display: inline-block;
      position: relative;
      margin: auto 0.5rem -0.25rem;
    }
  }

  @media only screen and (min-width: 1024px) {
    padding: 0;
  }
`;

const Banner = () => {
    return (
        <Background>
            <div className="container">
                <div className="col-1 hidden-m"/>
                <div className="col-10">
                    <Content>
                    <span>
                      Part of the
                    </span>
                        <a href="https://www.snaptrip.com/" target="_blank" rel="noreferrer">
                            <img src={Snaptrip} alt="Snaptrip"/>
                        </a>
                        <span>family</span>
                    </Content>
                </div>
            </div>
        </Background>
    );
};

export default Banner;