import React from "react";
import Layout from "./src/components/Layout";

export function wrapPageElement({element, props }) {
    let url;

    if ( props.data.region ) {
      url = props.data.region.slug.current;
    } else if ( props.data.blog ) {
      url = "/blog/" + props.data.blog.slug.current;
    } else if ( props.data.theme ) {
      url = props.data.theme.slug.current;
    } else if ( props.data.themedRegion ) {
      url = props.data.themedRegion.slug.current;
    } else if ( props.data.home ) {
      url = "/"; 
    } else if ( props.data.ppc ) {
      url = "/ppc/";
    } else if (props.location.pathname.includes("/contact/")) {
      url = "/contact/";
    } else if (props.location.pathname.includes("/blog/")) {
      url = "/blog/";
    } else if (props.location.pathname.includes("/terms-of-service/")) {
      url = "/terms-of-service/";
    } else if (props.location.pathname.includes("/privacy-policy/")) {
      url = "/privacy-policy/";
    } else if (props.location.pathname.includes("/about-us/")) {
      url = "/about-us/"
    } else {
      url = "/";
    }

    return(
        <Layout {...props} url={url}>
            {element}
        </Layout>
    );
}

export const onRenderBody = ({ setHeadComponents }) => {
  if (process.env.GATSBY_APP_ENV === "LWHT") {
    setHeadComponents([
      <script
        async
        key="google-optimize"
        src="https://www.googleoptimize.com/optimize.js?id=OPT-TRDF3C4"
      />,
    ]);
  }
};