import React, { useCallback, useEffect, useState} from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Banner from "./Banner";

// images
import Loupe from "../assets/icons/loupe.svg";
import PhoneIcon from "../assets/icons/phone.svg";
import WhitePhoneIcon from "../assets/icons/whitePhone.svg";

const Navi = styled.div`
    display: ${props => props.removeBar ? "flex" : "block"};
    justify-content: center;
    align-items: center;
`;

const NavStyles = styled.nav`

    .nav-container {
        position: fixed;
        width: 100%;
        z-index: 100;
    }
    .container-nav {
        height: auto;
    }

    .mob-menu {
        background: transparent;
        position: absolute;
        border: none;
        top: 0;
        width: 2rem;
        left: 1.25rem;
        height: 4.688rem;
        padding: 0;
        cursor: pointer;

        &:focus,
        &:active {
            outline: none;
        }

        .nav_burger {
            font: inherit;
            overflow: visible;
            transition-timing-function: linear;
            transition-duration: .15s;
            transition-property: opacity, filter;
            text-transform: none;
            color: inherit;
            border: 0;
            background-color: transparent;
            position: relative;
        }
      
        .nav_inner,
        .nav_inner::before,
        .nav_inner::after {
            content: "";
            position: absolute;
            height: .25rem;
            transition-timing-function: ease;
            transition-duration: .15s;
            transition-property: transform;
            border-radius: .25rem;
            width: 2rem;
            left: 0;
            background: var(--black);
        }

        .nav_inner {
            top: 50%;
            display: block;
            transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            transition-duration: .22s;

            &.active {
                transition-delay: .12s;
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                transform: rotate(225deg);

                &::before {
                    top: 0;
                    transition: top .1s ease-out, opacity .1s ease-out .12s;
                    opacity: 0;
                }

                &::after {
                    bottom: 0;
                    transition: bottom 0.1s ease-out,transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
                    transform: rotate(-90deg);
                }
            }

            &::before {
                top: -0.5rem;
                transition: top .1s ease-in .25s, opacity .1s ease-in;
            }

            &::after {
                bottom: -0.5rem;
                transition: bottom 0.1s ease-in 0.25s,transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }
        }
    }

    .contact-mobile {
        position: absolute;
        border: none;
        top: 0;
        width: 2rem;
        right: 1.25rem;
        height: 4.688rem;
        padding: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        
        img {
          height: 1.75rem;
        }

        @media only screen and (min-width: 1024px) {
          display: none;
        }
    }

  .nav {
        background: var(--white);
        height: 7.438rem;
        position: relative;
        z-index: 12;

        &.coloured-bg {
            background: var(--primary);
            
            .nav-item, 
            .nav-item a,
            .nav-item p,
            .nav-item span {
                color: white;
            }
            .nav-item.search-box p{
                color: black;
            }

            .mob-menu {
                .nav_burger {
                    .nav_inner {
                        background: #FFF;
                        
                        &:before, &:after {
                            background: #FFF;
                        }
                    }
                }
            }
        }
    }

    ul {
        list-style: none;
        padding: 0;
    }

    .nav-list {
        padding: 0;
        margin-top: 0;
        height: 4.688rem;
        display: flex;
        justify-content: center;
    }

    .nav-item {
        margin: 0 2rem 0 0;
        height: 100%;
        display: none;
        float: left;
        line-height: 4.688rem;
        cursor: pointer;
        user-select: none;
        font-weight: 600;
        color: var(--black);
        font-size: .875rem;

        a {
            color: var(--black);
            text-decoration: none;
        }

        &.logo {
            display: flex;
            align-items: center;
            margin: 0;

            a {
                display: flex;
            }

            img {
                width: 7.813rem;
                height: 100%;
            }
        }

        &.search-box {
            float: right;
            margin-right: 0;
        }
      
      &.contact {
        display: none;
      }
    }

    .VH .nav-item.logo img {
         width: 5rem;
    }

    .VH .subnav {

        pointer-events: none;

        &.visible {
            pointer-events: auto;
        }
     }

    .subnav {
        position: absolute;
        left: 0;
        width: 100%;
        background: rgba(0,0,0,0.8);
        z-index: 10;
        height: calc(100vh - 7.438rem);
        top: -100vh;
        opacity: 0;
        transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
        overflow-y: scroll;
      
        &.visible {
            opacity: 1;
            top: 7.438rem;
        }

        &-close {
            font-size: 1.75rem;
            position: absolute;
            top: 1rem;
            right: 0;
            z-index: 10;
            cursor: pointer;
            box-sizing: border-box;
            padding: .625rem;
            color: var(--white);
        }

        &-pad {
            padding: 2rem 0 0 0;
            position: relative;
        }

        &-list {
            padding: 0 1.25rem;
        }

        &-item {
            margin-bottom: 2.25rem;
            color: var(--white);
            font-weight: 600;
            font-size: 1rem;
            cursor: pointer;
            user-select: none;

            a {
                color: var(--white);
                font-weight: 600;
                font-size: 1rem;
                text-decoration: none;
            }

            p {
                margin: 0;
                display: inline;
                position: relative;

                &::before {
                    content: "";
                    height: 0.1rem;
                    background: var(--primary);
                    left: 0;
                    position: absolute;
                    bottom: -0.3rem;
                    width: 0;
                    transition: width ease 0.25s;
                }

                &.visible::before {
                    width: 100%;
 
                }
            }
        }
    }

    //mob only

    .mob {
        &-list {
            max-height: 0;
            transition: max-height 0.5s ease;
            overflow: hidden;
            margin: 0;

            &.visible {
                max-height: 40rem;
            }
        }

        &-item {
            margin: 1.25rem;
            user-select: none;

            &:last-child {
                margin: 1.25rem 1.25rem 0;
            }

            &:first-child {
                margin: 2.25rem 1.25rem 1.25rem;
            }
        }
    }

    @media only screen and (min-width: 1024px) {
        .mob-menu {
            display: none;
        }

        .nav-list {
            display: block;
        }

        .nav-item {
            display: inline;

            &.logo {
                margin: 0 4.125rem 0 0;
            }
        
            &.powered {
                float: right;
                display: flex;
                align-items: center;
                cursor: initial;
                color: var(--black);
                margin: 0;
            }
        }

        .subnav {

            &.visible {
                z-index: 11;
            }

            &-list {
                display: grid;
                grid-template-columns: auto auto auto auto;
                grid-gap: 1rem;
                padding: 0;
            }

            &-item {
                margin-bottom: 1.25rem;
            }

            &-pad {
                padding: 5rem 0 0 0;
            }
        }

      .nav-item {
        &.contact {
          display: flex;
          align-items: center;

          a {
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
              height: 1.5rem;
              margin-right: .5rem;
            }

            div {
              display: none;

              @media (min-width: 1220px) {
                display: flex;
                flex-direction: column;

                p {
                  font-size: .875rem;
                  line-height: .875rem;
                }

                span {
                  font-size: .688rem;
                  line-height: .688rem;
                }
              }
            }
          }
        }
      }
    }

  @media only screen and (max-width: 1300px) {
    .nav-item {
      margin: 0 1.25rem 0 0;


      &.logo {
        margin: 0 2.125rem 0 0;
      }
    }
  }
`;

const SearchBox = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0.5rem 0.75rem 1rem;
  box-sizing: border-box;
  background: var(--white);
  border: 1px solid var(--mdGrey);
  opacity: 0;
  margin: 0.75rem 0 auto 0;
  border-radius: .313rem;
  width: 18rem;
  height: 3.125rem;
  cursor: ${props => props.visible ? "text" : "default"};
  
  p {
    margin-bottom: 0;
    line-height: 1;
  }

  @media only screen and (min-width: 1024px) {
    display: flex;
    opacity: ${props => props.visible ? "1" : "0"};
    transition: 0.3s;
  }

  @media only screen and (max-width: 1500px) {
    width: 17rem;
  }
  
  @media only screen and (max-width: 1400px) {
    width: 12rem;
  }

  @media only screen and (max-width: 1300px) {
    width: 13.5rem;
  }

  @media only screen and (max-width: 1100px) {
    width: 11rem;
  }

  @media only screen and (max-width: 1050px) {
    width: 10rem;
  }
`;

const IconBox = styled.div`
  height: 2rem;
  width: 2rem;
  cursor: ${props => props.visible ? "pointer" : "default"};
  display: flex;
    
  img {
    height: 100%;
    width: 100%;
    z-index: 10;
  }
`;

const MobileIconBox = styled.div`
  display: block;
  position: fixed;
  bottom: 1.25rem;
  right: 1.25rem;
  height: 3.125rem;
  width: 3.125rem;
  cursor: pointer;
  z-index: 10;
  
  img {
    height: 100%;
    width: 100%;
    fill: var(--secondary);
  }

  @media only screen and (min-width: 1024px) {    
    display: none;
  }
`;

const Nav = (props) => {
    const [showSubnav, setShowSubnav] = useState(false);
    const [showSubnavTheme, setShowSubnavTheme] = useState(false);
    const [showMobNav, setShowMobNav] = useState(false);
    const [showMobDestinations, setShowMobDestinations] = useState(false);
    const [showMobThemes, setShowMobThemes] = useState(false);
    const [isSearchForm, setSearchForm] = useState(false);
    const [searchBoxVisible, setSearchBoxVisible] = useState(false);

    const App = process.env.GATSBY_APP_ENV || "LWHT";

    let { location } = props;

    let searchForm;

    const toggleSubnav = () => {
        setShowSubnavTheme(false);
        setShowSubnav(!showSubnav);
    }

    const toggleSubnavTheme = () => {
        setShowSubnav(false);
        setShowSubnavTheme(!showSubnavTheme);
    }

    const toggleMobNav = () => {
        setShowMobNav(!showMobNav);
        setShowMobDestinations(false);
        setShowMobThemes(false)
    }

    const toggleMobDestination = () => {
        setShowMobThemes(false);
        setShowMobDestinations(!showMobDestinations)
    }

    const toggleMobTheme = () => {
        setShowMobDestinations(false);
        setShowMobThemes(!showMobThemes);
    }

    const redirect = () => {
        setShowSubnav(false);
        setShowSubnavTheme(false);
        setShowMobNav(false);
        setShowMobDestinations(false);
        setShowMobThemes(false);
    }

    const handleScroll = useCallback(() => {
        if (typeof window !== 'undefined') {
            const { scrollY } = window;

            if ((scrollY > 300) && (searchBoxVisible === false)) {
                setSearchBoxVisible(true);
            } else {
                setSearchBoxVisible(false)
            }
        }
    }, []);

    useEffect(() => {
        searchForm = document.getElementById('search-form');

        if (searchForm !== null && isSearchForm === false) {
            setSearchForm(true);
        } else if (searchForm === null && isSearchForm === true) {
            setSearchForm(false);
        }

        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll, searchForm, location]);

    const scrollToForm = () => {
        if (searchForm !== null){
            const regionInput = document.getElementById('region');

            setTimeout(() => {
                if (typeof window !== 'undefined') {
                    if (searchBoxVisible === true){
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    }
                }
                regionInput.focus();
            }, 50);
        }
    };

    function setFocus(idInput){
        document.getElementById(idInput).focus();
    }

    return (
        <NavStyles>
            <div className={`nav-container ${App}`} >
                <Navi className={`nav ${props.settings?.colouredNav ? "coloured-bg": ""}`} removeBar={props.settings?.removeBar}>
                    { !props.settings?.removeBar && <Banner/>}

                    <div className="container container-nav">
                        <div className="col-1 hidden-m"/>

                        <button aria-label="Menu" className="mob-menu" onClick={() => toggleMobNav()}>
                            <div className="nav_burger">
                                <div className={`nav_inner ${showMobNav ? "active" : ""}`}/>
                            </div>
                        </button>

                        <a href="tel:+442036370812" className="contact-mobile">
                            <img src={ props.settings?.colouredNav ? WhitePhoneIcon : PhoneIcon} alt="Phone"/>
                        </a>

                        <ul className="nav-list col-10">
                            <li className="nav-item logo" >
                                <Link to="/" onClick={() => redirect()}>
                                    <img alt="Logo" src={props.settings?.colouredNav ? props.settings?.whiteLogo.asset.fixed.src : props.settings?.logo.asset.fixed.src}/>
                                </Link>
                            </li>
                            <li className="nav-item" onClick={() => toggleSubnav()}>
                                Destinations
                            </li>
                            { App !== "VH" &&
                             <li className="nav-item">
                                <Link to="/blog/" onClick={() => redirect()}>
                                    Inspiration
                                </Link>
                            </li>
                            }
                            { App !== "VH" &&
                            <li className="nav-item" onClick={() => toggleSubnavTheme()}>
                                Holiday Type
                            </li>
                            }
                            <li className="nav-item">
                                <a href="https://www.snaptripgroup.com/list-your-property" target="_blank">List your property</a>
                            </li>
                            <li className="nav-item contact">
                                <a href="tel:+442036370812">
                                    <img src={ props.settings?.colouredNav ? WhitePhoneIcon : PhoneIcon} alt="Phone"/>
                                    <div>
                                        <p>0203 637 0812</p>
                                        <span>Open until 9:30pm</span>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item search-box">
                                {isSearchForm && <SearchBox visible={searchBoxVisible ? 1 : 0} onClick={() => scrollToForm()}>
                                    <p>Start your search</p>
                                    <IconBox visible={searchBoxVisible ? 1 : 0}>
                                        <img src={Loupe} alt="Search"/>
                                    </IconBox>
                                </SearchBox>}

                            </li>
                        </ul>
                    </div>
                </Navi>

                <div className={`subnav ${showSubnav ? "visible" : ""}`}>
                    <div className="container">
                    <div className="col-1 hidden-m"/>
                    <div className="col-10 subnav-pad">
                        <div className="subnav-close hidden-m" onClick={() => toggleSubnav()}>✕</div>
                        <ul className="subnav-list">
                            {props.regions.map(function(region, index){
                                if (region.showInNav !== false) {
                                    return (
                                        <li key={ index } className="subnav-item">
                                            <Link to={"/"+ region.slug.current} onClick={() => redirect()}>{region.name}</Link>
                                        </li>
                                    )
                                }
                            })}
                            {App === "DFH" &&
                                <li className="subnav-item">
                                    <Link to="/cornwall-coastal/" onClick={() => redirect()}>
                                        Cornwall Coastal
                                    </Link>
                                </li>
                            }
                        </ul>
                    </div>
                    </div>
                </div>

                <div className={`subnav ${showSubnavTheme ? "visible" : ""}`}                       >
                    <div className="container">
                    <div className="col-1 hidden-m"/>
                    <div className="col-10 subnav-pad">
                        <div className="subnav-close hidden-m" onClick={() => toggleSubnavTheme()}>✕</div>
                        <ul className="subnav-list">
                            {props.themeArray.map(function(theme, index){
                                if (theme.showInNav !== false) {
                                    return (
                                        <li key={ index } className="subnav-item">
                                            <Link to={"/"+theme.slug.current} onClick={() => redirect()}>{theme.name}</Link>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>
                    </div>
                </div>

                <div className={`subnav ${showMobNav ? "visible" : ""}`}>
                    <div className="col-1 hidden-m"/>
                    <div className="col-10 subnav-pad">
                        <ul className="subnav-list">
                            <li className="subnav-item">
                                <p className={` ${showMobDestinations ? "visible" : ""}`} onClick={() => toggleMobDestination()}>Destinations</p>
                                <ul className={`mob-list ${showMobDestinations ? "visible" : ""}`}>
                                    {props.regions.map(function(region, index){
                                        if (region.showInNav !== false) {
                                            return (
                                                <li key={ index } className="mob-item">
                                                    <Link to={"/"+ region.slug.current} onClick={() => redirect()}>{region.name}</Link>
                                                </li>
                                            )
                                        }
                                    })}
                                </ul>
                            </li>
                            { App !== "VH" &&
                            <li className="subnav-item">
                                <Link to="/blog/" onClick={() => redirect()}>
                                    Inspiration
                                </Link>
                            </li>
                            }
                            { App !== "VH" &&
                            <li className="subnav-item">
                                <p className={` ${showMobThemes ? "visible" : ""}`} onClick={() => toggleMobTheme()}>Themes</p>
                                <ul className={`mob-list ${showMobThemes ? "visible" : ""}`}>
                                    {props.themeArray.map(function(theme, index){
                                        if (theme.showInNav !== false) {
                                            return (
                                                <li key={ index } className="mob-item">
                                                    <Link to={"/"+theme.slug.current} onClick={() => redirect()}>{theme.name}</Link>
                                                </li>
                                            )
                                        }
                                    })}
                                </ul>
                            </li>
                            }
                             <li className="subnav-item">
                                <a href="https://www.snaptripgroup.com/list-your-property" target="_blank">List your property</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {isSearchForm && (
                <MobileIconBox onClick={() => setFocus('region')}>
                    <img src={Loupe} alt="Search"/>
                </MobileIconBox>
            )}
        </NavStyles>
    );
}

export default Nav;